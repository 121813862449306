import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {LocationPolicyService} from '../../../../../../services/tps/location-policy.service';
import {Subscription} from 'rxjs';
import {IPageChangeEvent} from '@covalent/core/paging';
import {ITdDataTableSortChangeEvent, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {LocationPolicy} from '../../../../../../models/tps/location-policy';
import {CollectionComponent} from '../../../../../global/collection/collection.component';
import {LocationService} from '../../../../../../services/tps/location.service';

@Component({
  selector: 'app-location-policies',
  templateUrl: './location-policies.component.html',
  styleUrls: ['./location-policies.component.scss'],
  providers: [LocationPolicyService, LocationService]
})
export class LocationPoliciesComponent extends CollectionComponent implements OnInit, OnDestroy {
  loaderName = 'policy-list';
  routeSub: Subscription;
  dataSub: Subscription;
  companyId: string;
  policies: LocationPolicy[] = [];
  filteredPolicies: LocationPolicy[] = [];
  filteredPolicyCount = 0;

  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'departureAllowed', label: 'departure_allowed', sortable: true, active: false},
    {name: 'departureBlocked', label: 'departure_blocked', sortable: true, active: false},
    {name: 'destinationAllowed', label: 'destination_allowed', sortable: true, active: false},
    {name: 'destinationBlocked', label: 'destination_blocked', sortable: true, active: false},
    {name: 'apps', label: 'connected_locations', sortable: false, maxSize: 'max-width-400'},
  ];

  constructor(private _translateService: TranslateService,
              protected injector: Injector,
              private _locationPolicyService: LocationPolicyService,
              private _locationService: LocationService,
              private _titleService: Title) {
    super(injector);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    _translateService.get(['location_policies']).subscribe((translations: any) => {
      this._titleService.setTitle(translations.location_policies + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations.location_policies);
    });
  }

  ngOnInit(): void {
    this.routeSub = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      this._navigationService.setActionLink(`/groups/${this.companyId}/location-policies/add`);
      this._loadingService.register(this.loaderName);
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.dataSub.unsubscribe();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    let filteredData: LocationPolicy[] = this.policies;

    filteredData = this._dataTableService.filterData(filteredData, this.searchTerm, true);
    filteredData = this._dataTableService.sortData(filteredData, this.sortBy, this.sortOrder);
    filteredData = this._dataTableService.pageData(filteredData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredPolicyCount = filteredData.length;
    this.filteredPolicies = filteredData;
  }

  loadData = () => {
    let locations;
    this._locationService.getAll({where: {companyId: this.companyId}})
      .subscribe((i) => {
        locations = i;
        let mapInstalls = i.map((i) => {
          return i._id;
        });
        this.dataSub = this._locationPolicyService.getAll({
          where: {companyId: this.companyId},
          include: 'locations'
        }).subscribe((policies: LocationPolicy[]) => {
          console.log(`[LocationPoliciesComponent.loadData]:`, policies);
          this.policies = policies;
          this.filteredPolicyCount = policies.length;

          this.policies.forEach((d) => {
            if (d.locations.length > 0) {
              d.locations.forEach((l) => {
                if (mapInstalls.includes(l._id)) {
                  const i = mapInstalls.indexOf(l._id);
                  const install = d.locations[i];
                  if (install) {
                    if (d['apps']) {
                      d['apps'] = `${d['apps']}, ${install.name}`;
                      d['tooltip'] = `${d['tooltip']}
                                      * ${install.name}`;
                    } else {
                      d['apps'] = `${install.name}`;
                      d['tooltip'] = `* ${install.name}`;
                    }
                  }
                }
              });
            }
          });

          this.filter();

          const self = this;
          setTimeout(function () {
            self._loadingService.resolve(self.loaderName);
          }, 500);
        }, error => {
          console.error(error);
          this._loadingService.resolve(this.loaderName);
        })
      })
  }

  open(id: string): void {
    console.log(`[LocationPoliciesComponent.open]: id =>`, id);
    if (id) {
      this._router.navigate([`/groups/${this.companyId}/location-policies/${id}/edit/`]);
    }
  }

}
