<mat-card-content *ngIf="(companies.length > 0 || operatorCompanies.length > 0 || companies.length > 0 ||
(this.driver &&
this.driver.Installation))"
                  class="push-bottom-none">
  <mat-card-title>{{ (this.parent.openFromLogin ? 'choose_workspace' : 'switch_workspace') | translate | ucFirst }}</mat-card-title>
  <mat-card-subtitle *ngIf="!this.parent.openFromLogin">{{
      (this.parent.openFromLogin ?
        'select_company_workspace_subtitle' : 'select_company_workspace_subtitle') | translate | ucFirst
    }}
  </mat-card-subtitle>

  <ng-template tdLoading="workspace">
    <mat-nav-list class="push-bottom push-top">
      <td-search-box #searchBox id="searchBox" (searchDebounce)="search($event)" [alwaysVisible]="true"
                     class="push-left push-right"
                     placeholder="{{'search_here'|translate}}"></td-search-box>
      <mat-divider></mat-divider>
      <mat-tab-group>
        <mat-tab *ngIf="supportContractCompanies">
          <ng-template mat-tab-label>
            {{ 'company_contract_active'|translate|ucFirst }} ({{ supportContractCompanies.length }})
          </ng-template>
          <ng-template *ngIf="supportContractCompanies" [ngForOf]="filteredData['supportContractCompanies']"
                       layout="row" let-item
                       let-last="last" ngFor>
            <a (click)="openWorkSpace('/groups/'+item.id+'/'+target+'/', item.id)"
               [skipLocationChange]="false"
               class="{{(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')}} trial-company-{{(item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
         item.ActiveContract.foreignStatus : 'trial') : 'expired')}}" mat-list-item>
              <mat-icon class="avatar-icon">person</mat-icon>
              <div class="company-name">
                {{ item.name }}
              </div>

              <div class="contract-email">{{ item.email }}</div>

              <div class="contract-status">{{
                  (item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
                      'company_contract_' + (item.ActiveContract.foreignStatus ? item.ActiveContract.foreignStatus : 'active') :
                      'company_trial_active') :
                    'company_trial_expired') | translate | ucFirst
                }}
              </div>
            </a>
            <mat-divider></mat-divider>
          </ng-template>

          <ng-template *ngIf="taxiIDContractCompanies" [ngForOf]="filteredData['taxiIDContractCompanies']" layout="row"
                       let-item
                       let-last="last" ngFor>
            <a (click)="openWorkSpace('/groups/'+item.id+'/'+target+'/', item.id)"
               [skipLocationChange]="false"
               class="{{(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')}} taxiid-company-{{(item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
         item.ActiveContract.foreignStatus : 'trial') : 'expired')}}" mat-list-item>
              <mat-icon class="avatar-icon">person</mat-icon>
              <div class="company-name">
                {{ item.name }}
              </div>

              <div class="contract-email">{{ item.email }}</div>

              <div class="contract-status">{{
                  (item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
                      'company_contract_' + (item.ActiveContract.taxiIDContract ? 'taxiid' : 'active') :
                      'company_trial_active') :
                    'company_trial_expired') | translate | ucFirst
                }}
              </div>
            </a>
            <mat-divider></mat-divider>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="supportTrialCompanies">
          <ng-template mat-tab-label>
            {{ 'company_trial_active'|translate|ucFirst }} ({{ supportTrialCompanies.length }})
          </ng-template>
          <ng-template *ngIf="supportTrialCompanies" [ngForOf]="filteredData['supportTrialCompanies']" layout="row"
                       let-item let-last="last"
                       ngFor>
            <a (click)="openWorkSpace('/groups/'+item.id+'/'+target+'/', item.id)"
               [skipLocationChange]="false"
               class="recover-workspace {{(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')}} trial-company-{{(item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
         item.ActiveContract.foreignStatus : 'trial') : 'expired')}}" mat-list-item>
              <mat-icon class="avatar-icon">person</mat-icon>
              <div class="company-name">
                {{ item.name }}
              </div>

              <div class="contract-email">{{ item.email }}</div>
              <div class="contract-status">{{( item.ActiveContract ? item.ActiveContract.currentPeriodEnd : '') | date }}</div>
              <div class="contract-status">{{
                  (item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
                      'company_contract_' + (item.ActiveContract.foreignStatus ? item.ActiveContract.foreignStatus : 'active') :
                      'company_trial_active') :
                    'company_trial_expired') | translate | ucFirst
                }}
              </div>

              <button (click)="addDaysToTrial(item, 7); $event.stopPropagation()" class="link-button link-button-days"
                      color="primary" flex mat-raised-button>{{ 'add_days_trial' | translate | ucFirst }}
              </button>
            </a>
            <mat-divider></mat-divider>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="unpaidContractCompanies">
          <ng-template mat-tab-label>
            {{ 'company_contract_unpaid'|translate|ucFirst }}
          </ng-template>
          <ng-template *ngIf="unpaidContractCompanies" [ngForOf]="filteredData['unpaidContractCompanies']" layout="row"
                       let-item
                       let-last="last" ngFor>
            <a (click)="openWorkSpace('/groups/'+item.id+'/'+target+'/', item.id)"
               [skipLocationChange]="false"
               class="{{(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')}} trial-company-{{(item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
         item.ActiveContract.foreignStatus : 'trial') : 'expired')}}" mat-list-item>
              <mat-icon class="avatar-icon">person</mat-icon>
              <div class="company-name">
                {{ item.name }}
              </div>

              <div class="contract-email">{{ item.email }}</div>

              <div class="contract-status">{{
                  (item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
                      'company_contract_' + (item.ActiveContract.foreignStatus ? item.ActiveContract.foreignStatus : 'active') :
                      'company_trial_active') :
                    'company_trial_expired') | translate | ucFirst
                }}
              </div>
            </a>
            <mat-divider></mat-divider>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="expiredContractCompanies">
          <ng-template mat-tab-label>
            {{ 'company_contract_expired'|translate|ucFirst }}
          </ng-template>
          <ng-template *ngIf="expiredContractCompanies" [ngForOf]="filteredData['expiredContractCompanies']"
                       layout="row" let-item
                       let-last="last" ngFor>
            <a (click)="openWorkSpace('/groups/'+item.id+'/'+target+'/', item.id)"
               [skipLocationChange]="false"
               class="recover-workspace {{(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')}} trial-company-{{(item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
         item.ActiveContract.foreignStatus : 'trial') : 'expired')}}" mat-list-item>
              <mat-icon class="avatar-icon">person</mat-icon>
              <div class="company-name">
                {{ item.name }}
              </div>

              <div class="contract-email">{{ item.email }}</div>
              <div class="contract-status">{{
                  (item.ActiveContract ? (item.ActiveContract.type !== 'free-trial' ?
                      'company_contract_' + (item.ActiveContract.foreignStatus ? item.ActiveContract.foreignStatus : 'active') :
                      'company_trial_active') :
                    'company_trial_expired') | translate | ucFirst
                }}
              </div>

              <button (click)="addDaysToTrial(item, 7); $event.stopPropagation()" class="link-button link-button-days"
                      color="primary" flex mat-raised-button>{{ 'add_days_trial' | translate | ucFirst }}
              </button>
            </a>
            <mat-divider></mat-divider>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="deletedContractCompanies">
          <ng-template mat-tab-label>
            {{ 'company_contract_deleted'|translate|ucFirst }}
          </ng-template>
          <ng-template *ngIf="deletedContractCompanies" [ngForOf]="filteredData['deletedContractCompanies']"
                       layout="row" let-item
                       let-last="last" ngFor>
            <a
              class="recover-workspace {{(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')}}"
              mat-list-item>
              <mat-icon class="avatar-icon">person</mat-icon>
              <div class="company-name">
                {{ item.name }}
              </div>


              <div class="contract-email">{{ item.email }}</div>
              <div class="contract-status">{{ item.archived | date }}</div>

              <button (click)="recoverWorkspace(item)" class="link-button"
                      color="primary" flex mat-raised-button>{{ 'recover_workspace' | translate | ucFirst }}
              </button>
            </a>
            <mat-divider></mat-divider>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <a (click)="openWorkSpace('/profile/createGroup')"
         class="login-link"
         href="/profile/createGroup"
         mat-list-item>
        <mat-icon class="avatar-icon">add</mat-icon>
        {{ 'create_new_group'|translate|ucFirst }}
      </a>
    </mat-nav-list>
  </ng-template>
</mat-card-content>
<mat-card-content *ngIf="companies.length < 1 && operatorCompanies.length < 1 && companies.length < 1 && !loading">
  <mat-nav-list class="push-bottom">
    <mat-card-title>{{ 'select_login_no_companies_title'|translate }}</mat-card-title>
    <ng-template class="push-left push-right" tdLoading="workspace">
      <br>{{ 'select_login_no_companies_subtitle'|translate }}
      <br><br>
      <button (click)="logout()" color="accent">{{ 'logout'|translate|ucFirst }}</button>
    </ng-template>
  </mat-nav-list>
</mat-card-content>
