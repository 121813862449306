<mat-card-subtitle>
  {{ 'product_details_subtitle' | translate | ucFirst}}
</mat-card-subtitle>

<div [formGroup]="form" flex="100">
  <mat-form-field class="push-right" flex>
    <input #name formControlName="name" matInput placeholder="Name" type="text">
  </mat-form-field>

  <mat-form-field class="push-right" flex>
    <mat-select class="row-select"
                flex="33"
                formControlName="type"
                placeholder="{{ 'type' | translate | ucFirst }}"
                required>
      <mat-option *ngFor="let type of types" [value]="type">
        {{ 'tps_selection_' + type | translate | ucFirst }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input #priority formControlName="priority" matInput
           min="1"
           placeholder="{{ 'priority' | translate | ucFirst }}"
           type="number">
  </mat-form-field>
</div>
