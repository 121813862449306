import {Injectable} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from './global.service';
import {UtilityService} from './utility.service';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';
import {Company} from '../models/company';
import {CompanyWebUserService} from './companyWebUser.service';

const base = environment.apiBaseUrl;

@Injectable()
export class CompanyService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService, private _companyWebUserService: CompanyWebUserService) {
    super(_http, _vault, _utilityService);
    this.setModel('companies');
  }

  getNewJWT(companyId: string, daAppInstallId: string): Observable<any> {
    return this._http.get<any>(
      environment.apiBaseUrl +
      `/companies/jwt?companyId=${companyId}&daAppInstallId=${daAppInstallId}`,
      {headers: this.getHeaders()});
  }

  getExtendedDispatchers(companyId: string): Observable<any> {
    return this._http.get<any>(
      environment.apiBaseUrl +
      `/companies/${companyId}/getExtendedDispatchers`,
      {headers: this.getHeaders()});
  }

  installTps(company: Company, daAppInstallId: string): Observable<any> {
    const uri = `${environment.apiBaseUrl}/DaAppInstalls/registerWithTPS`;
    return this._http.post<any>(uri, {companyId: company.id, daAppInstallId, country: company.country}, {headers: this.getHeaders()});
  }

  createAccountLink = (data?: Object, context?: string): Observable<any> =>
    this._http.post<any>(
      `${base}/psp/stripeAccountLink`, data,
      {headers: this.getHeaders(context)},
    );

  connectStripeAccount = (data?: Object, context?: string): Observable<any> => {
    return this._http.post<any>(
      `${base}/psp/connectStripeAccount`, data,
      {headers: this.getHeaders(context)},
    );
  }

  connectPayPalAccount = (data?: any, context?: string): Observable<any> => {
    data.portalUrl = environment.portalUrl;
    return this._http.post<any>(
      `${base}/psp/connectPayPalAccount`, data,
      {headers: this.getHeaders(context)},
    );
  }

  requestPaypalOnboardingLink = (data?: any, context?: string): Observable<any> => {
    data.portalUrl = environment.portalUrl;
    return this._http.post<any>(
      `${base}/psp/stripeAccountLink`, data,
      {headers: this.getHeaders(context)},
    );
  }

  getCompanies(filter?: any): Observable<any> {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);

    if (!user) {
      return new EmptyObservable();
    }

    if (filter) {
      if (!filter['where']) {
        filter['where'] = {};
      }
      filter['where'].ownerId = user.id;
      filter['where'].ownerType = 'WebPortalUser';
    } else {
      filter = {
        'where': {
          'ownerId': user.id,
          'ownerType': 'WebPortalUser',
        }
      };
    }

    const filterString = JSON.stringify(this._utilityService.restrictCompanyFilter(filter, true));
    return this._http.get(`${environment.apiBaseUrl}/companies/?filter=${filterString}`, {headers: this.getHeaders()});
  }

  archive(companyId: string): Observable<any> {
    return this._http.get(`${environment.apiBaseUrl}/companies/${companyId}/archive`, {headers: this.getHeaders()});
  }

  synchronizeCalendar(companyId: string): Observable<any> {
    return this._http.get(`${environment.apiBaseUrl}/companies/${companyId}/synchronizeCalendar`, {headers: this.getHeaders()});
  }

  getCompaniesWhereOwner(filter?: any): Observable<any> {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    if (!user) {
      return new EmptyObservable();
    }

    const companyUserFilter = {
      'where': {
        'userId': user.id,
        'type': 'owner',
        'companyId': {
          'exists': true
        }
      },
      'include': {
        'relation': 'Company'
      },
      'order': 'name ASC'
    };
    if (filter['where']['hadTrial']) {
      companyUserFilter['where']['hadTrial'] = filter['where']['hadTrial'];
    }
    const cFilterString = JSON.stringify(this._utilityService.restrictCompanyFilter(companyUserFilter, true));
    return this._http.get(`${environment.apiBaseUrl}/CompanyWebUsers/?filter=${cFilterString}`, {headers: this.getHeaders()});
  }

  recoverWorkspace(data): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/companies/recoverWorkspace`, data, {headers: this.getHeaders()});
  }

  addDaysToTrial(param: { days: any; id: any }) {
    return this._http.post(`${environment.apiBaseUrl}/companies/${param.id}/addDaysToTrial`, param, {headers: this.getHeaders()});
  }
}
